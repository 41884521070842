import { template as template_b2dbb58318914fe2aae19096f9bb21ab } from "@ember/template-compiler";
import { concat } from "@ember/helper";
import emoji from "discourse/helpers/emoji";
import escape from "discourse-common/lib/escape";
import { i18n } from "discourse-i18n";
const title = (description, endsAt, timezone)=>{
    let content = escape(description);
    if (endsAt) {
        const until = moment.tz(endsAt, timezone).format(i18n("dates.long_date_without_year"));
        content += `\n${i18n("until")} ${until}`;
    }
    return content;
};
const UserStatusBubble = template_b2dbb58318914fe2aae19096f9bb21ab(`
  <div class="user-status-background">
    {{emoji
      @status.emoji
      title=(title @status.description @status.ends_at @timezone)
      alt=(concat ":" @status.emoji ":")
    }}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default UserStatusBubble;
