import { template as template_f3694db611e3469d94a42c1d8c972b5c } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const RepliesCell = template_f3694db611e3469d94a42c1d8c972b5c(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="posts"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="replies"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default RepliesCell;
