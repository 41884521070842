import { template as template_26edef6b37e24ea5ab3c3629eeb5ab70 } from "@ember/template-compiler";
import { concat, hash } from "@ember/helper";
import { LinkTo } from "@ember/routing";
import { i18n } from "discourse-i18n";
const GroupActivityFilter = template_26edef6b37e24ea5ab3c3629eeb5ab70(`
  <li>
    <LinkTo
      @route={{concat "group.activity." @filter}}
      @query={{hash category_id=@categoryId}}
    >
      {{i18n (concat "groups." @filter)}}
    </LinkTo>
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default GroupActivityFilter;
