import { template as template_022e255e71ed4b3d812e8a03c2e59ac0 } from "@ember/template-compiler";
const WelcomeHeader = template_022e255e71ed4b3d812e8a03c2e59ac0(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
