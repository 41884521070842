import { template as template_d41092e3622846d1a1bff6d9cca78328 } from "@ember/template-compiler";
const FKControlMenuContainer = template_d41092e3622846d1a1bff6d9cca78328(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
