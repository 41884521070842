import { template as template_4ff9ff72e7464841a49db823f39d07a6 } from "@ember/template-compiler";
const FKLabel = template_4ff9ff72e7464841a49db823f39d07a6(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
